body {
  margin: auto;
  padding-top: 250px;
  min-height: 100vh;
  overflow-x: hidden;
}

.nav-link {
  font-weight: bold;
  font-size: 1.2em;
}

nav img {
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgba(51, 51, 51, 0.49);
}

.icons {
  height: 3.5rem;
  width: 3.5rem;
  }

.element-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  opacity: 0.8;
}

.rounded-custom {
  border-top-left-radius: 20px!important;
  border-bottom-right-radius: 20px!important;
}

.active {
  border-bottom: 3px solid #f80;
}

.fadein {
  transition: 0.4s;
  animation: fadein 1.5s;
}

.text-shadow {
  text-shadow: 1px 1px #1d1d1d;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bounce {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-3px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@media screen and (max-width: 992px) {
  body {
    padding-top: 100px;
  }

  h1 {
    text-align: center;
    font-size: 1.5em;
  }

  #profile-photo {
    height: 50px;
  }

  .navbar, .row {
    background-image: none!important;
  }

  .nav-link {
    font-size: 1em;
  }
}